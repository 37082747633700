import { Button, Image } from '@chakra-ui/react'

import theme from '../../../theme'

const SocialButton = ({ label, image, onClick }) => {
    return (
        <Button
            _hover={{}}
            _focus={{}}
            _active={{}}
            w={'full'}
            size={'lg'}
            bgColor={'transparent'}
            border={'1px solid'}
            borderColor={'gray.300'}
            boxShadow={'sm'}
            borderRadius={'full'}
            leftIcon={<Image src={image} boxSize={'25px'} />}
            onClick={onClick}
        >
            {label}
        </Button>
    )
}

export default SocialButton