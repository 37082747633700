import { useEffect } from 'react'
import {
    Stack,
    Heading
} from '@chakra-ui/react'

import { useLocation } from 'react-router-dom'

import Card from '../Components/Shared/Card'
import Logo from '../Components/Shared/Logo'
import PrimaryButton from '../Components/Shared/Buttons/PrimaryButton'
import TextButton from '../Components/Shared/Buttons/TextButton'

import { IoCheckmarkCircleOutline } from 'react-icons/io5'

import { createBillingPortalLink } from '../Api/Stripe'

const OrderSuccessful = () => {
    const location = useLocation()

    const handleManageSubscription = async () => {
        const response = await createBillingPortalLink()

        if (response.data.error) {
            console.log('error')
        } else {
            window.location.replace(response.data.url)
        }
    }

    return (
        <Card>
            <Stack
                direction={'column'}
                p={5}
                spacing={10}
            >

                <Logo />

                <Stack
                    direction={'column'}
                    align={'center'}
                    spacing={5}
                    pb={5}
                >

                    <Heading fontWeight={600} color={'gray.800'} textAlign={'center'} pb={5}>
                        Your order was successful
                    </Heading>

                    <IoCheckmarkCircleOutline
                        size={'100px'}
                        color={'#48BB78'}
                    />

                    <a href={'https://daddysteach.com/'} style={{ width: '100%' }}>
                        <PrimaryButton
                            label={'Start using daddysteach'}
                        />
                    </a>

                    {/* <TextButton
                        label={'Manage subscription'}
                        onClick={handleManageSubscription}
                    /> */}

                </Stack>

            </Stack>
        </Card>
    )
}

export default OrderSuccessful