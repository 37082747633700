import {
    Alert,
    AlertIcon,
    AlertTitle
} from '@chakra-ui/react'

const CustomAlert = ({ status, title }) => {
    return (
        <Alert
            status={status}
            borderRadius={'full'}
        >
            <AlertIcon />
            <AlertTitle textAlign={'center'}>{title}</AlertTitle>
        </Alert>
    )
}

export default CustomAlert