import React, { useState, useRef, useEffect } from 'react'
import {
    Stack,
    Heading,
    Text,
    IconButton
} from '@chakra-ui/react'
import { MdVisibility, MdVisibilityOff } from "react-icons/md"
import { useNavigate } from 'react-router-dom'
import ReCAPTCHA from 'react-google-recaptcha'
import { useGoogleLogin } from '@react-oauth/google'

import theme from '../theme'
import config from '../config'

import { signin, googleAuth } from '../Api/Auth'

import Card from '../Components/Shared/Card'
import CustomInput from '../Components/Shared/CustomInput'
import PrimaryButton from '../Components/Shared/Buttons/PrimaryButton'
import TextButton from '../Components/Shared/Buttons/TextButton'
import SocialButton from '../Components/Shared/Buttons/SocialButton'
import CustomAlert from '../Components/Shared/CustomAlert'
import Logo from '../Components/Shared/Logo'

import GoogleIcon from '../Assets/google_icon.png'

const Login = () => {
    const navigate = useNavigate()
    const recaptchaRef = useRef()

    const [isPasswordHidden, setIsPasswordHidden] = useState(true)
    const [alert, setAlert] = useState({
        status: '',
        title: ''
    })
    const [formData, setFormData] = useState({
        email: '',
        password: ''
    })
    const [captchaValue, setCaptchaValue] = useState('')
    const [isLoading, setIsLoading] = useState(false)

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }

    const handleCaptcha = (value) => {
        setCaptchaValue(value)
    }

    const handleSubmit = async () => {
        setIsLoading(true)

        const token = null
        //const token = await recaptchaRef.current.executeAsync();
        //recaptchaRef.current.reset();

        const response = await signin({
            email: formData.email,
            password: formData.password,
            captchaToken: token
        });

        if (response.data.error) {
            setAlert({ status: 'error', title: response.data.message })
        } else {
            window.location.replace(config.WEBSITE_URL)
        }

        setIsLoading(false)
    }

    const handleGoogle = useGoogleLogin({
        onSuccess: async tokenResponse => {
            setIsLoading(true)
            const response = await googleAuth({ token: tokenResponse.access_token })

            if (response.data.error) {
                setAlert({ status: 'error', title: response.data.message })
            } else {
                window.location.replace(config.WEBSITE_URL)
                //setAlert({ status: 'success', title: response.data.message })
            }
            setIsLoading(false)
        },
        onError: () => setAlert({ status: 'error', message: 'There was an error signing in with Google. Please try again later.' })
    });
	
	useEffect(() => {
		document.title = 'daddysteach - Login'
	}, [])

    return (
        <Card>
            <Stack
                direction={'column'}
                p={5}
                spacing={10}
            >

                <Logo />

                <Stack
                    direction={'column'}
                    align={'center'}
                    spacing={5}
                >
                    {alert.status &&
                        <CustomAlert
                            status={alert.status}
                            title={alert.title}
                        />
                    }

                    <Heading fontWeight={600} color={'gray.800'}>Log in</Heading>

                    <Stack
                        direction={'row'}
                        align={'center'}
                        justify={'center'}
                    >
                        <Text color={'gray.600'} fontSize={'lg'}>Don't have an account?</Text>
                        <TextButton
                            label={'Sign up'}
                            onClick={() => navigate('/signup')}
                        />
                    </Stack>

                    <CustomInput
                        placeholder={'Email address'}
                        type={'text'}
                        name={'email'}
                        value={formData.email}
                        onChange={handleChange}
                    />

                    <CustomInput
                        placeholder={'Password'}
                        type={isPasswordHidden ? 'password' : 'text'}
                        name={'password'}
                        value={formData.password}
                        onChange={handleChange}
                        rightElement={
                            <IconButton
                                _hover={{}}
                                _focus={{}}
                                _active={{}}
                                icon={isPasswordHidden ? <MdVisibilityOff /> : <MdVisibility />}
                                style={{ backgroundColor: 'transparent', boxShadow: 'none', color: theme.default.defaultColor }}
                                onClick={() => setIsPasswordHidden((isPasswordHidden) => !isPasswordHidden)}
                            />
                        }
                    />

                    <ReCAPTCHA
                        ref={recaptchaRef}
                        sitekey={config.SITE_KEY}
                        size={'invisible'}
                        onChange={handleCaptcha}
                        onExpired={() => recaptchaRef.current.reset()}
                    />

                    <PrimaryButton
                        label={'Log in'}
                        onClick={handleSubmit}
                        isLoading={isLoading}
                    />

                    <TextButton
                        label={'Forgot your password?'}
                        onClick={() => navigate('/reset-password')}
                    />
                </Stack>

                <Stack direction={'column'} pb={5}>
                    <SocialButton
                        image={GoogleIcon}
                        label={'Sign in with Google'}
                        onClick={() => handleGoogle()}
                    />
                </Stack>
            </Stack>
        </Card>
    )
}

export default Login