import { useEffect } from 'react'

const NotFound = () => {
    useEffect(() => {
        window.location.replace('/')
    }, [])
    
  return (
    <div>Not Found. Redirecting...</div>
  )
}

export default NotFound