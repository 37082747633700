const config_dev = {
    API_BASE_URL: 'http://localhost:4500',
    WEBSITE_URL: 'https://daddysteach.com',
    SITE_KEY: '6LdPfhonAAAAAMUZdAaDkbupBK3a1dzoSuUZm6Cv',
    GOOGLE_OAUTH_CLIENT_ID: '784080694642-ultnn6087o3nf266fqo7mcitv07hvomq.apps.googleusercontent.com'
}

const config = {
    API_BASE_URL: 'https://api.daddysteach.com',
    WEBSITE_URL: 'https://daddysteach.com',
    SITE_KEY: '6LfIui4oAAAAACesZlxscnTis-oMkJ9GyUIenlhW',
    GOOGLE_OAUTH_CLIENT_ID: '153655030273-hpvglfhcjktkhu5e668eau5ff0n41734.apps.googleusercontent.com'
}

export default config