import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'

import Login from './Pages/Login'
import Signup from './Pages/Signup'
import ResetPassword from './Pages/ResetPassword'
import SelectPlan from './Pages/SelectPlan'
import OrderSuccessful from './Pages/OrderSuccessful'
import NotFound from './Pages/NotFound'

const App = () => {
  return (
    <Router>
      <Routes>
        <Route exact path='/' element={<Login />} />
        <Route exact path='/signup' element={<Signup />} />
        <Route exact path='/reset-password' element={<ResetPassword />} />
        <Route exact path='/select-plan' element={<SelectPlan />} />
        <Route exact path='/order-successful' element={<OrderSuccessful />} />
        <Route exact path='*' element={<NotFound />} />
      </Routes>
    </Router>
  );
}

export default App;
