import React, { useState, useEffect, useRef } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import Reaptcha from 'reaptcha'

import config from '../config'

import StepOne from '../Components/ResetPassword/StepOne'
import StepTwo from '../Components/ResetPassword/StepTwo'
import StepThree from '../Components/ResetPassword/StepThree'

const ResetPassword = () => {
  const recaptchaRef = useRef(null)

  const [step, setStep] = useState(1)
  const [formData, setFormData] = useState({
    email: '',
    code: '',
    password: '',
    confirmPassword: ''
  })
  const [recaptchaResponse, setRecaptchaResponse] = useState(null);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  const onVerify = recaptchaResponse => {
    setRecaptchaResponse(recaptchaResponse);
  };

  useEffect(() => {
    document.title = 'daddysteach - Reset Password'
  }, [])

  switch (step) {
    case 1:
      return (
        <StepOne
          setStep={setStep}
          formData={formData}
          handleChange={handleChange}
          recaptchaRef={recaptchaRef}
          recaptchaResponse={recaptchaResponse}
          Reaptcha={<ReCAPTCHA
            ref={recaptchaRef}
            sitekey={config.SITE_KEY}
            size={'invisible'}
            onVerify={onVerify}
          />}
        />
      )
    case 2:
      return (
        <StepTwo 
        setStep={setStep} 
        formData={formData} 
        setFormData={setFormData} 
        handleChange={handleChange}
        recaptchaRef={recaptchaRef}
        recaptchaResponse={recaptchaResponse}
        Reaptcha={<ReCAPTCHA
          ref={recaptchaRef}
          sitekey={config.SITE_KEY}
          size={'invisible'}
          onVerify={onVerify}
        />} 
        />
      )
    case 3:
      return (
        <StepThree 
        setStep={setStep} 
        formData={formData} 
        handleChange={handleChange}
        recaptchaRef={recaptchaRef}
        recaptchaResponse={recaptchaResponse}
        Reaptcha={<ReCAPTCHA
          ref={recaptchaRef}
          sitekey={config.SITE_KEY}
          size={'invisible'}
          onExpired={() => recaptchaRef.current.reset()}
        />} 
        />
      )
    default:
      return (
        <StepOne 
        setStep={setStep} 
        formData={formData} 
        handleChange={handleChange}
        recaptchaRef={recaptchaRef}
        recaptchaResponse={recaptchaResponse}
        Reaptcha={<Reaptcha
          ref={recaptchaRef}
          sitekey={config.SITE_KEY}
          size={'invisible'}
          onVerify={onVerify}
        />}  
        />
      )
  }

  // return (
  //   step === 1 ? <StepOne /> : step === 2 ? <StepTwo /> : <StepThree />
  // )
}

export default ResetPassword