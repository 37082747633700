import { Flex, Container, useMediaQuery } from '@chakra-ui/react'

const Card = ({ children }) => {
    const isDesktop = useMediaQuery("(min-width: 768px)")

    return (
        <Flex
            minH={'100vh'}
            align={'center'}
            justify={'center'}
            bgColor={'gray.100'}
        >
            <Container
                bgColor={'whiteAlpha.800'}
                maxW={isDesktop[0] && 'md'}
                h={!isDesktop[0] && '100vh'}
                borderRadius={'lg'}
                border={'1px solid'}
                borderColor={'gray.300'}
                boxShadow={'md'}
            >
                {children}
            </Container>
        </Flex>
    )
}

export default Card