// Step Two -- Enter code sent to email

import { useState, useRef } from 'react'

import {
  Stack,
  Heading,
  Text,
  Highlight,
  HStack,
  PinInput,
  PinInputField
} from '@chakra-ui/react'

import theme from '../../theme'
import config from '../../config'

import { enterCode } from '../../Api/Auth'

import Card from '../Shared/Card'
import PrimaryButton from '../Shared/Buttons/PrimaryButton'
import CustomAlert from '../Shared/CustomAlert'
import Logo from '../Shared/Logo'

import ReCAPTCHA from 'react-google-recaptcha'

const StepTwo = ({ setStep, formData, setFormData, handleChange, recaptchaRef}) => {
  const [alert, setAlert] = useState({
    status: '', title: ''
  })
  const [isLoading, setIsLoading] = useState(false)
  const [recaptchaResponse, setRecaptchaResponse] = useState(null)

  const handleCaptcha = (value) => {
    setRecaptchaResponse(value)
  }

  const handleSubmit = async () => {
    setIsLoading(true)

    const token = await recaptchaRef.current.executeAsync();
    recaptchaRef.current.reset();

    const response = await enterCode({
      code: formData.code,
      captchaToken: token
    })

    if (response.data.error) {
      setAlert({ active: true, status: 'error', title: response.data.message })
      setFormData({ ...formData, code: '' })
    } else {
      setStep(3)
    }

    setIsLoading(false)
  }

  return (
    <Card>
      <Stack
        direction={'column'}
        p={5}
        spacing={10}
      >

        <Logo />

        <Stack
          direction={'column'}
          align={'center'}
          spacing={5}
          pb={5}
        >
          {alert.status &&
            <CustomAlert
              status={alert.status}
              title={alert.title}
            />
          }

          <Heading fontWeight={600} color={'gray.800'}>Enter Code</Heading>

          <Text
            textAlign={'center'}
            color={'gray.500'}
            fontSize={'md'}
          >
            We sent a code to your email.
          </Text>

          <HStack>
            <PinInput
              size={'lg'}
              focusBorderColor={theme.default.defaultColor}
              otp
              value={formData.code}
              onChange={(value) => handleChange({
                target: {
                  name: 'code',
                  value: value
                }
              })}            
            >
              <PinInputField />
              <PinInputField />
              <PinInputField />
              <PinInputField />
            </PinInput>
          </HStack>

          <ReCAPTCHA
            ref={recaptchaRef}
            sitekey={config.SITE_KEY}
            size={'invisible'}
            onChange={handleCaptcha}
            onExpired={() => recaptchaRef.current.reset()}
          />

          <PrimaryButton
            label={'Continue'}
            onClick={handleSubmit}
            isLoading={isLoading}
          />
        </Stack>
      </Stack>
    </Card>
  )
}

export default StepTwo