import { InputGroup, Input, InputRightElement } from '@chakra-ui/react'

import theme from '../../theme'

const CustomInput = ({ placeholder, type, rightElement, name, value, onChange }) => {
    return (
        <InputGroup size={'lg'}>
            <Input
                focusBorderColor={theme.default.defaultColor}
                borderColor={'gray.300'}
                borderRadius={'full'}
                placeholder={placeholder}
                name={name}
                type={type}
                value={value}
                onChange={onChange}
            />
            {rightElement &&
                <InputRightElement>
                    {rightElement}
                </InputRightElement>
            }
        </InputGroup>
    )
}

export default CustomInput