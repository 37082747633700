import { Button } from '@chakra-ui/react'

import theme from '../../../theme'

const PrimaryButton = ({ label, onClick, isLoading }) => {
    return (
        <Button
            _hover={{}}
            _focus={{}}
            _active={{}}
            w={'full'}
            size={'lg'}
            bgColor={theme.default.defaultColor}
            borderRadius={'full'}
            onClick={onClick}
            isLoading={isLoading}
            color={'white'}
        >
            {label}
        </Button>
    )
}

export default PrimaryButton