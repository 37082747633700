// Step One -- Enter your email address

import { useState } from 'react'
import {
  Stack,
  Heading,
  Text
} from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'

import { enterEmail } from '../../Api/Auth'

import Card from '../Shared/Card'
import CustomInput from '../Shared/CustomInput'
import PrimaryButton from '../Shared/Buttons/PrimaryButton'
import TextButton from '../Shared/Buttons/TextButton'
import CustomAlert from '../Shared/CustomAlert'
import Logo from '../Shared/Logo'

import config from '../../config'

import ReCAPTCHA from 'react-google-recaptcha'

const StepOne = ({ setStep, formData, handleChange, recaptchaRef }) => {
  const navigate = useNavigate()

  const [alert, setAlert] = useState({
    status: '', title: ''
  })
  const [isLoading, setIsLoading] = useState(false)
  const [recaptchaResponse, setRecaptchaResponse] = useState(null)

  const handleCaptcha = (value) => {
    setRecaptchaResponse(value)
  }

  const handleSubmit = async () => {
    setIsLoading(true)

    //await recaptchaRef.current.reset();
    //await recaptchaRef.current.execute();
    const token = await recaptchaRef.current.executeAsync();
    recaptchaRef.current.reset();

    const response = await enterEmail({
      email: formData.email,
      captchaToken: token
      //captchaToken: recaptchaResponse
    })

    if (response.data.error) {
      setAlert({ active: true, status: 'error', title: response.data.message })
    } else {
      setStep(2)
    }

    setIsLoading(false)
  }

  return (
    <Card>
      <Stack
        direction={'column'}
        p={5}
        spacing={10}
      >

        <Logo />

        <Stack
          direction={'column'}
          align={'center'}
          spacing={5}
          pb={5}
        >
          {alert.status &&
            <CustomAlert
              status={alert.status}
              title={alert.title}
            />
          }

          <Heading fontWeight={600} color={'gray.800'}>Reset Password</Heading>

          <Text
            textAlign={'center'}
            color={'gray.500'}
            fontSize={'md'}
          >
            Enter your email address, and we'll send you a code to reset your password.
          </Text>

          <CustomInput
            placeholder={'Email address'}
            type={'text'}
            name={'email'}
            value={formData.email}
            onChange={handleChange}
          />

          <PrimaryButton
            label={'Reset Password'}
            onClick={handleSubmit}
            isLoading={isLoading}
          />

          {/* {Reaptcha} */}
          <ReCAPTCHA
            ref={recaptchaRef}
            sitekey={config.SITE_KEY}
            size={'invisible'}
            onChange={handleCaptcha}
            onExpired={() => recaptchaRef.current.reset()}
          />

          <TextButton
            label={'Back to log in'}
            onClick={() => navigate('/')}
          />
        </Stack>
      </Stack>
    </Card>
  )
}

export default StepOne