// Step Three - Type new password

import { useState } from 'react'

import {
  Stack,
  Heading,
  Text,
  IconButton
} from '@chakra-ui/react'
import { MdVisibility, MdVisibilityOff } from "react-icons/md"

import theme from '../../theme'
import config from '../../config'

import { enterNewPassword } from '../../Api/Auth'

import Card from '../Shared/Card'
import CustomInput from '../Shared/CustomInput'
import PrimaryButton from '../Shared/Buttons/PrimaryButton'
import CustomAlert from '../Shared/CustomAlert'
import Logo from '../Shared/Logo'

import ReCAPTCHA from 'react-google-recaptcha'

const StepThree = ({ setStep, formData, handleChange, recaptchaRef }) => {
  const [isPasswordHidden, setIsPasswordHidden] = useState(true)
  const [alert, setAlert] = useState({
    status: '', title: ''
  })
  const [isLoading, setIsLoading] = useState(false)
  const [recaptchaResponse, setRecaptchaResponse] = useState(null)

  const handleCaptcha = (value) => {
    setRecaptchaResponse(value)
  }

  const handleSubmit = async () => {
    setIsLoading(true)

    const token = await recaptchaRef.current.executeAsync();
    recaptchaRef.current.reset();

    const response = await enterNewPassword({
      code: formData.code,
      email: formData.email,
      password: formData.password,
      confirmPassword: formData.confirmPassword,
      captchaToken: token
    })

    if (response.data.error) {
      setAlert({ active: true, status: 'error', title: response.data.message })
    } else {
      window.location.replace(config.WEBSITE_URL)
    }

    setIsLoading(false)
  }

  return (
    <Card>
      <Stack
        direction={'column'}
        p={5}
        spacing={10}
      >

        <Logo />

        <Stack
          direction={'column'}
          align={'center'}
          spacing={5}
          pb={5}
        >
          {alert.status &&
            <CustomAlert
              status={alert.status}
              title={alert.title}
            />
          }

          <Heading fontWeight={600} color={'gray.800'}>Set new password</Heading>

          <Text
            textAlign={'center'}
            color={'gray.500'}
            fontSize={'md'}
          >
            Must be at least 5 characters.
          </Text>

          <CustomInput
            placeholder={'Password'}
            type={isPasswordHidden ? 'password' : 'text'}
            name={'password'}
            value={formData.password}
            onChange={handleChange}
            rightElement={
              <IconButton
                _hover={{}}
                _focus={{}}
                _active={{}}
                icon={isPasswordHidden ? <MdVisibilityOff /> : <MdVisibility />}
                style={{ backgroundColor: 'transparent', boxShadow: 'none', color: theme.default.defaultColor }}
                onClick={() => setIsPasswordHidden((isPasswordHidden) => !isPasswordHidden)}
              />
            }
          />

          <CustomInput
            placeholder={'Confirm password'}
            type={isPasswordHidden ? 'password' : 'text'}
            name={'confirmPassword'}
            value={formData.confirmPassword}
            onChange={handleChange}
            rightElement={
              <IconButton
                _hover={{}}
                _focus={{}}
                _active={{}}
                icon={isPasswordHidden ? <MdVisibilityOff /> : <MdVisibility />}
                style={{ backgroundColor: 'transparent', boxShadow: 'none', color: theme.default.defaultColor }}
                onClick={() => setIsPasswordHidden((isPasswordHidden) => !isPasswordHidden)}
              />
            }
          />

          <ReCAPTCHA
            ref={recaptchaRef}
            sitekey={config.SITE_KEY}
            size={'invisible'}
            onChange={handleCaptcha}
            onExpired={() => recaptchaRef.current.reset()}
          />

          <PrimaryButton
            label={'Reset Password'}
            onClick={handleSubmit}
            isLoading={isLoading}
          />

          {/* <TextButton
            label={'Back to log in'}
          /> */}
        </Stack>
      </Stack>
    </Card>
  )
}

export default StepThree