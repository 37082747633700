import { useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import {
  Stack,
  Heading,
  Text,
  Image,
  Wrap,
  WrapItem,
  useDisclosure
} from '@chakra-ui/react'

import config from '../config'
import theme from '../theme'

import Card from '../Components/Shared/Card'
import Logo from '../Components/Shared/Logo'
import Plan from '../Components/SelectPlan/Plan'
import PrimaryButton from '../Components/Shared/Buttons/PrimaryButton'
import RedirectingModal from '../Components/SelectPlan/RedirectingModal'

import plansFeatures from '../Assets/plans.json'

import visa from '../Assets/PaymentMethods/visa.svg'
import mastercard from '../Assets/PaymentMethods/mastercard.svg'
import discover from '../Assets/PaymentMethods/discover.svg'
import amex from '../Assets/PaymentMethods/amex.svg'
import applepay from '../Assets/PaymentMethods/applepay.svg'
import googlepay from '../Assets/PaymentMethods/googlepay.svg'

const SelectPlan = () => {
  const [selectedPlan, setSelectedPlan] = useState('Free')
  const navigate = useNavigate()
  const { state } = useLocation()
  const { isOpen, onOpen, onClose } = useDisclosure()

  useEffect(() => {
    document.title = 'daddysteach - Select a plan'

    if (!state?.email) {
      navigate('/')
    }
  }, [])

  const handleSelectFreePlan = () => {
    window.location.replace(config.WEBSITE_URL)
  }

  return (
    <>
      <Card>
        <Stack
          direction={'column'}
          p={5}
          spacing={10}
        >
          <Logo />

          <Stack
            direction={'column'}
            align={'center'}
            spacing={5}
          >
            <Heading fontWeight={600} color={'gray.800'} textAlign={'center'} pb={5}>
              Pick the plan that's best for you
            </Heading>

            <Plan
              name={'Free'}
              description={'Perfect for students who just need a little extra help.'}
              price={'$0/mo'}
              priceHighlight={'$0'}
              features={plansFeatures[0]['free']}
              selectedPlan={selectedPlan}
              setSelectedPlan={setSelectedPlan}
            />

            <Plan
              name={'Standard'}
              description={'Ideal for students who are taking challenging courses.'}
              price={'$2.49/mo'}
              priceHighlight={'$2.49'}
              features={plansFeatures[0]['standard']}
              selectedPlan={selectedPlan}
              setSelectedPlan={setSelectedPlan}
            />

            <Plan
              name={'VIP'}
              description={'Perfect for students who are serious about their studies.'}
              price={'$3.99/mo'}
              priceHighlight={'$3.99'}
              features={plansFeatures[0]['vip']}
              selectedPlan={selectedPlan}
              setSelectedPlan={setSelectedPlan}
            />

            <Text
              textAlign={'center'}
              color={'gray.500'}
              fontSize={'md'}
            >
              Compare plans at our <a href={'https://daddysteach.com/pricing'} style={{ textDecoration: 'underline', textDecorationColor: theme.default.defaultColor }} target={'_blank'}>Pricing page</a>
            </Text>

            {/* <PrimaryButton
            label={'Select Plan'}
          /> */}

            <Stack w={'full'} textAlign={'center'}>
              {selectedPlan === 'Free' &&
                <PrimaryButton
                  label={'Select Plan'}
                  onClick={handleSelectFreePlan}
                />
              }

              {selectedPlan !== 'Free' &&
                <PrimaryButton
                  label={'Select Plan'}
                  onClick={onOpen}
                />

              }
            </Stack>

            <Stack align={'center'}>
              <Text>Accepted Payments</Text>
              <Wrap>
                <WrapItem>
                  <Image src={visa} />
                </WrapItem>
                <WrapItem>
                  <Image src={mastercard} />
                </WrapItem>
                <WrapItem>
                  <Image src={discover} />
                </WrapItem>
                <WrapItem>
                  <Image src={amex} />
                </WrapItem>
                <WrapItem>
                  <Image src={applepay} />
                </WrapItem>
                <WrapItem>
                  <Image src={googlepay} />
                </WrapItem>
              </Wrap>
            </Stack>
          </Stack>
        </Stack>
      </Card>

      <RedirectingModal
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        selectedPlan={selectedPlan}
      //redirectUrl={`https://daddysteach.gumroad.com/l/plans?wanted=true&email=${state.email}&variant=${selectedPlan}`}
      />
    </>
  )
}

export default SelectPlan