import { Button } from '@chakra-ui/react'

import theme from '../../../theme'

const TextButton = ({ label, onClick }) => {
    return (
        <Button
            _active={{}}
            _focus={{}}
            variant={'link'}
            color={theme.default.defaultColor}
            fontSize={'lg'}
            onClick={onClick}
        >
            {label}
        </Button>
    )
}

export default TextButton