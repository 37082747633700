import config from '../config'
import axios from 'axios'

const headers = {
    headers: {
        'Content-Type': 'application/json'
        //'sessionId': localStorage.getItem('sessionId')
    },
	withCredentials: true

}

const handleError = (e) => {
	console.log(e)
    return {
        data: {
            error: true,
            message: 'The server is currently unavailable. Please try again later.'
        }
    }
}

const signup = async (body) => {
    try {
        const response = await axios.post(config.API_BASE_URL + '/auth/signup', body, headers)

        return response
    } catch (error) {
        return handleError()
    }
}

const signin = async (body) => {
    try {
		/*
        const response = await axios.post(config.API_BASE_URL + '/auth/signin', body, {
			headers: headers.headers,
			withCredentials: true
		})
		*/
		const response = await axios.post(config.API_BASE_URL + '/auth/signin', body, headers)

        return response
    } catch (error) {
        return handleError(error)
    }
}

const enterEmail = async (body) => {
    try {
        const response = await axios.post(config.API_BASE_URL + '/auth/reset-password/one', body, headers)

        return response
    } catch (error) {
        return handleError()
    }
}

const enterCode = async (body) => {
    try {
        const response = await axios.post(config.API_BASE_URL + '/auth/reset-password/two', body, headers)

        return response
    } catch (error) {
        return handleError()
    }
}

const enterNewPassword = async (body) => {
    try {
        const response = await axios.post(config.API_BASE_URL + '/auth/reset-password/three', body, headers)

        return response
    } catch (error) {
        return handleError()
    }
}

const googleAuth = async (body) => {
    try {
        const response = await axios.post(config.API_BASE_URL + '/auth/google', body, headers)

        return response
    } catch (error) {
        return handleError()
    }
}

export { signup, signin, enterEmail, enterCode, enterNewPassword, googleAuth }