import {
    Stack,
    Heading,
    Text,
    IconButton,
    Highlight,
    Divider
} from '@chakra-ui/react'

import { IoCheckmarkCircleSharp } from 'react-icons/io5'

import theme from '../../theme'
import config from '../../config'

const Plan = ({ name, description, price, priceHighlight, selectedPlan, setSelectedPlan, features }) => {
    return (
        <Stack
            w={'full'}
            border={'3px solid'}
            borderColor={selectedPlan === name ? theme.default.defaultColor : 'gray.300'}
            borderRadius={'md'}
            p={3}
            onClick={() => setSelectedPlan(name)}
            _hover={{ cursor: 'pointer' }}
        >
            <Stack direction={'row'} align={'center'} justify={'space-between'}>
                <Text fontWeight={700} fontSize={'2xl'}>{name}</Text>
                <Text>
                    <Highlight query={priceHighlight} styles={{ fontWeight: 700, fontSize: '3xl', color: theme.default.defaultColor }}>
                        {price}
                    </Highlight>
                </Text>
            </Stack>

            <Text color={'gray.600'}>{description}</Text>

            <Divider borderColor={'gray.300'} />

            <Stack direction={{ base: 'column', md: 'row' }}>
                {
                    features.map((feature) => (
                        <Stack direction={'row'} align={'center'} fontSize={'lg'} key={feature.feature}>
                            <IoCheckmarkCircleSharp color={feature.limited ? '#D69E2E' : '#38A169'} />
                            <Text>{feature.feature}</Text>
                        </Stack>
                    ))
                }
            </Stack>
        </Stack>
    )
}

export default Plan