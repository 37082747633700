import { Stack, Heading } from '@chakra-ui/react'

import theme from '../../theme'

const Logo = () => {
    return (
        <Stack
            direction={'row'}
            align={'center'}
            justify={'center'}
            spacing={3}
            pt={5}
        >
            <Heading fontSize={'4xl'} color={theme.default.defaultColor}>daddysteach</Heading>
        </Stack>
    )
}

export default Logo