export default {
    default: {
        //defaultColor: '#ffb562'
        defaultColor: '#0D9494',
        defaultTransparentBackground: 'rgba(13, 148, 148, .2)',
        secondaryColor: '#995798',
        tertiaryColor: '#2380BB'
    },
    backgroundColor: {
        primaryLight: '#FFFFFF',
        primaryDark: '#171923'
    },
    iconButton: {
        primaryLight: '#E2E8F0',
        primaryDark: '#1A202C'
    },
    textColor: {
        primaryLight: '',
        primaryDark: '',
        secondaryLight: '#4A5568',
        secondaryDark: '#A0AEC0'
    }
}