import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    Divider,
    Stack,
    Image,
    Heading,
    Text
} from '@chakra-ui/react'

import config from '../../config'

import { createCheckoutSession } from '../../Api/Stripe'

import planId from '../../Assets/planId.json'
import creditcard from '../../Assets/credit_card.svg'


const RedirectingModal = ({ isOpen, onClose, selectedPlan }) => {
    //const [countdown, setCountdown] = useState(3)

    /*
    useEffect(() => {
        
        if (isOpen) {
            const newTimer = setTimeout(() => {
                window.location.replace(redirectUrl)
            }, 1500)

            setCountdown(newTimer)
        } else {
            clearTimeout(countdown)
            setCountdown(null)
        }
        
    }, [isOpen])
    */

    useEffect(() => {
        const createCheckoutLink = async () => {
            const response = await createCheckoutSession({
                priceId: planId[selectedPlan].priceId 
            })
    
            if (response.data.error) {
                alert('Error. Please Try again later.')
            } else {
                window.location.replace(response.data.url)
            }
        }

        if (isOpen) {
            if (selectedPlan === 'Free') {
                //console.log('RedirectingModal.selectedPlan.Free')
                window.location.replace(config.WEBSITE_URL)
            } else {
                //console.log('RedirectingModal.createCheckoutLink')
                createCheckoutLink()
            }
        }
    }, [isOpen])

    return (
        <Modal isOpen={isOpen} onClose={onClose} isCentered>
            <ModalOverlay />
            <ModalContent>
                <ModalBody>
                    <Stack align={'center'} textAlign={'center'} spacing={5} py={5}>
                        <Image src={creditcard} width={'50%'} height={'50%'} />
                        <Heading fontWeight={700} fontSize={'2xl'} color={'gray.700'}>Redirecting to checkout...</Heading>
                        {/* <PrimaryButton
                            label={'Cancel'}
                            onClick={onClose}
                        /> */}
                    </Stack>
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}

export default RedirectingModal